@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.signals-triangle{
  width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right:  solid transparent;
	border-bottom: 100px solid #F3F3F3;
  position: absolute;
  bottom: 0;
  right: 0;
}

.news-triangle{
  width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right:  solid transparent;
	border-top: 100px solid #F3F3F3;
  position: absolute;
  top: 0;
  right: 0;
}
